import firebase from 'firebase/app';
import 'firebase/firebase-messaging';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

class Firebase {
  app!: firebase.app.App;

  init(): firebase.messaging.Messaging {
    const isAndroid = navigator.userAgent.indexOf('Android') > 0;
    console.log('Firebase.init(isAndroid): ' + isAndroid);
    if (isAndroid && !this.app) {
      try {
        this.app = firebase.initializeApp(firebaseConfig);
        console.log('firebase.ts: FCM initialized');
        console.log('firebase.ts(app): ' + JSON.stringify(this.app));
        return this.app.messaging();
      } catch (err) {
        console.log(
          'Firebase.init: FCM not initialized. ' + JSON.stringify(err)
        );
      }
    } else {
      console.log('Firebase.init: FCM not available on iPhone.');
    }
    return this.app.messaging();
  }
}

export const JnFirebase = new Firebase();
