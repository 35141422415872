
import { ref, defineComponent, onUpdated, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { JnService } from "@/services/jn.service";
import { useCookie } from "@vue-composable/cookie";
import { CrmInfo } from "@/models/crm/crmInfo.model";
import { Purchase } from "@/models/crm/purchase.model";
import { Service } from "@/models/crm/service.model";
import { User } from "@/models/crm/user.model";
import {
  FcmTopicsPlatform,
  FcmTopicsAreeTematiche,
  FcmTopicsAreeTematicheList,
} from "@/models/enums.model";
import { JnFirebase } from "@/firebase";
import "firebase/firebase-messaging";

import {
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonButton,
  IonPage,
  IonInput,
  IonCard,
  IonCardContent,
  IonContent,
  IonItem,
  IonIcon,
  IonLoading,
  IonImg,
  IonToast,
} from "@ionic/vue";

import { mail, key, eye, logIn } from "ionicons/icons";
import { addIcons } from "ionicons";
import * as eventBus from "@/services/event.bus.js";
addIcons({
  mail: mail,
  key: key,
  eye: eye,
  "log-in": logIn,
  /* 'arrow-forward': arrowForward,
  'arrow-down': arrowDown,*/
});
export default defineComponent({
  name: "JnLogin",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonPage,
    IonInput,
    IonLoading,
    IonContent,
    IonImg,
    IonIcon,
    IonCard,
    IonCardContent,
    IonButton,
    IonToast,
  },
  setup() {
    const email = ref("");
    const password = ref("");

    const showLoading = ref(false);
    const loadingMessage = ref("Carico i tuoi documenti...");

    const alertMessage = ref("");
    const showAlert = ref(false);
    const setAlertOpen = (state: boolean) => (showAlert.value = state);

    const store = useStore();
    const router = useRouter();

    async function checkToken(tokens: any, token: string) {
      console.log("Login.checkToken: checking token '" + token + "'");
      let found = false;
      if (tokens.status != 404) {
        for (const t of tokens) {
          if (t.token == token) {
            found = true;
          }
        }
      }
      if (!found) {
        console.log("Login.checkToken: new token, resubscribing...'");
        await JnService.fcmRegisterTopic(token, FcmTopicsPlatform.APP);
        let cookie = undefined;
        for (const topic of FcmTopicsAreeTematicheList) {
          cookie = useCookie("fcm-topic-" + topic);
          if (cookie.cookie.value == "true") {
            await JnService.fcmRegisterTopic(token, topic);
          }
        }
      }
    }

    async function getMaxNumSessions(username: string): Promise<number> {
      const user = await JnService.getCrmUser(username);
      if (user) {
        const purchaseOrder = await JnService.getActivePurchaseOrder(user.id);
        if (purchaseOrder && purchaseOrder.maxNumSession) {
          return Number(purchaseOrder.maxNumSessions);
        }
        return Number(process.env.VUE_APP_MAX_NUM_SESSIONS);
      }
      return Number(process.env.VUE_APP_MAX_NUM_SESSIONS);
    }

    async function goToRegistration() {
      router.push("/register");
    }

    async function signIn() {
      showLoading.value = true;
      loadingMessage.value = "Logging in...";
      const maxNumSessions = await getMaxNumSessions(email.value);
      const res = await JnService.login(
        email.value,
        password.value,
        maxNumSessions,
        process.env.VUE_APP_PRODUCT_ID
      );
      if (res.code == 200) {
        const user = await JnService.getCrmUser(email.value);
        const purchaseOrder = await JnService.getActivePurchaseOrder(user.id);
        console.log(purchaseOrder)
        let service = undefined;
        if (purchaseOrder) {
          service = await JnService.getService(purchaseOrder.serviceId);
        }
        const crmInfo = new CrmInfo(user, purchaseOrder, service);

        useCookie("username", email.value, {
          expires: 0.083,
        });
        useCookie("SESSIONID", res.data.idSession);

        if (crmInfo) {
          // store.commit('crmInfo', crmInfo);
          // Eventuale logica per capire se ha superato il paywall va messa qui
          eventBus.default.$emit("paywall", false);
          useCookie("paywall", "false", {
            expires: 0.083,
          });
        }
        eventBus.default.$emit("login", true);

        const docId = store.getters.docId;
        if (docId) {
          const isJuranews = store.getters.isJuranews;
          store.commit("docId", "undefined");
          store.commit("isJuranews", "undefined");
          if (isJuranews) {
            router.push("/docs/juranews/" + docId);
          } else {
            router.push("/docs/juranet/" + docId);
          }
        } else {
          router.push("/main");
        }

        /**
         * FCM Token Management
         */
        const isAndroid = navigator.userAgent.indexOf("Android") > 0;
        console.log("Login.signIn(isAndroid): " + isAndroid);
        if (isAndroid) {
          const tokens = await JnService.fcmGetTokens(
            user.username,
            FcmTopicsPlatform.APP
          );
          try {
            const messaging = JnFirebase.init();
            const token = await messaging!.getToken({
              vapidKey: process.env.VUE_APP_FCM_VAPID_KEY,
            });
            await checkToken(tokens, token);
            await JnService.fcmSaveToken(
              user.username,
              FcmTopicsPlatform.APP,
              token
            );
            console.log("Login.login(fcm-token): " + token);
          } catch (err) {
            console.log("Login.login(err): " + JSON.stringify(err));
          }
        }
      } else {
        showAlert.value = true;
        alertMessage.value = res.data;
        eventBus.default.$emit("auth", false);
      }
      showLoading.value = false;
    }

    return {
      signIn,
      goToRegistration,
      email,
      password,
      alertMessage,
      showAlert,
      loadingMessage,
      showLoading,
      setAlertOpen,
    };
  },
});
